<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: 100%">
    <div v-if="flag === true" >
      <div class="weui-cells" style="margin-top: 10px;" v-for="(item, i) in list" :key="i" >
        <a class="weui-cell weui-cell_access" href="javascript:;" @click="toInfo(item)">
          <div class="weui-media-box__hd">
            <img src="../../assets/image/icon_list.png" alt="" style="width:30px;margin-right:5px;">
          </div>
          <div class="weui-cell__bd">
            <p>任务名称：<span>{{item.node_Name}}</span><br>生成时间：<span>{{item.create_Time.replace('T', '')}}</span></p>
          </div>
          <div class="weui-cell__ft"></div>
        </a>
      </div>
    </div>
    <van-empty v-else description="暂无代办流程" />
  </van-pull-refresh>
</template>

<script>
  import store from "@/store";
  import Base64 from "@/util/Base64";
  import { Toast } from 'vant';
  import { getList } from "@/api/istManagement";
  import {Emas} from "@/assets/js/Emas";

  export default {
    name: "index",
    //进入当前页面前重新请求数据
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.fetchData()
      })
    },
    data() {
      return {
        isLoading: false,
        flag: true,
        list: [],
        queryForm: {
          intPageIndex: 1,
          intPageSize: 10,
          strOrderByFileds: 'ID DESC',
        },
        Search: {
          Type: '',
          Case_Type_ID: '',
          Case_ID: '',
          Case_Action: '',
          Staff_Name: '',
          Operator: 0,
        },
      }
    },
    created() {
      this.fetchData()
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/ist", "待办事项", "zzdzx.yy.gov.cn/ist")
    },
    methods: {
      async onRefresh() {
        let userInfo = store.getters['user/userInfo']
        this.Search.Operator = userInfo.user_ID
        const {success, data } = await getList(this.queryForm, this.Search)
        if(success) {
          this.list = data.data
          this.isLoading = false;
          Toast('刷新成功');
        } else {
          this.isLoading = false;
        }
      },
      async fetchData() {
        let userInfo = store.getters['user/userInfo']
        this.Search.Operator = userInfo.user_ID
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载...',
          forbidClick: true,
        });
        const { data } = await getList(this.queryForm, this.Search)
        Toast.clear()
        this.list = data.data
        this.flag = this.list.length > 0

      },
      toInfo(row) {
        let IST_Node_ID = Base64.encode(row.id)
        let App_ID = Base64.encode(row.pK_Value)
        this.$router.push({
          path: '/score',
          query: {
            App_ID: App_ID,
            IST_Node_ID: IST_Node_ID,
            Case_Action: row.case_Action
          },
        })
      }
    }
  }
</script>

<style scoped>
  @import "../../assets/css/jquery-weui.css";
  @import "../../assets/css/weui.css";
  @import "../../assets/css/demos.css";
  .weui-media-box__title-after {
    color: #9b9b9b;
    font-size: .45rem;
    float: right;
  }

  .weui-cell__ft::after {
    content: " ";
    display: inline-block;
    height: 6px;
    width: 6px;
    border-width: 2px 2px 0 0;
    border-color: #c8c8cd;
    border-style: solid;
    -webkit-transform: matrix(.71, .71, -.71, .71, 0, 0);
    transform: matrix(.71, .71, -.71, .71, 0, 0);
    position: relative;
    top: -2px;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 20px;
  }
  .weui-cell__bd span{
    color:#5288f5 ;
  }
</style>