import { request } from '@/util/request'

export function getList(params, data) {
  return request({
    url:
      '/api/ISTFlowNode/GetList?intPageIndex=' +
      params.intPageIndex +
      '&intPageSize=' +
      params.intPageSize +
      '&strOrderByFileds=' +
      params.strOrderByFileds,
    method: 'post',
    data: data,
  })
}

export function getISTInfo(params) {
  return request({
    url: '/api/ISTFlowNode/GetISTInfo',
    method: 'get',
    params,
  })
}

export function isLock(params) {
  return request({
    url: '/api/ISTFlowNode/IsLock',
    method: 'get',
    params,
  })
}

export function getISTNodeInfo(params) {
  return request({
    url: '/api/ISTFlowNode/GetISTNodeInfo',
    method: 'get',
    params,
  })
}
